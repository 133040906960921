<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="Search" clearable> </el-date-picker>
              </el-form-item>
              <el-form-item label="费用类型">
                <el-select v-model="searchForm.orderType" placeholder="请选择" size="small" style="width: 120px" @change="Search">
                  <el-option label="全部" value="0"></el-option>
                  <el-option label="代煎费" value="4"></el-option>
                  <el-option label="快递费" value="6"></el-option>
                  <el-option label="检查治疗项" value="2"></el-option>
                  <el-option label="附加费用" value="3"></el-option>
                  <el-option label="审方费" value="5"></el-option>
                  <el-option label="包装费" value="7"></el-option>
                  <el-option label="药剂师审方费" value="8"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单编号/开方医生/联系方式" @keyup.enter.native="Search"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="orderNum" label="订单编号"></el-table-column>
          <el-table-column prop="doctorName" label="开方医生" align="center"> </el-table-column>
          <el-table-column prop="doctorPhone" label="联系方式" align="center"></el-table-column>
          <el-table-column prop="orderType" label="费用类型" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.orderType==4">代煎</p>
              <p v-if="scope.row.orderType==6">快递</p>
              <p v-if="scope.row.orderType==2">检查治疗项</p>
              <p v-if="scope.row.orderType==3">附加费用</p>
              <p v-if="scope.row.orderType==5">审方费</p>
              <p v-if="scope.row.orderType==7">包装费</p>
              <p v-if="scope.row.orderType==8">药剂师审方费</p>
            </template>
          </el-table-column>
          <el-table-column prop="money" label="金额(元)" align="center"> </el-table-column>
          <el-table-column prop="addTime" label="收费时间" align="center"> 
            <template slot-scope="scope">
              {{ scope.row.addTime | timefilters }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient.js";
import { Department } from "@/components/HospitalDomain/Department.js";
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug);
    var department = new Department(this.TokenClient, this.Services.Authorization);
    return {
      OutpatientDomain: outpatient,
      Department: department,
      headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      departmentData: [],
      searchForm: {
        keyWord: "",
        datepicker: '',
        orderType: '0',
      },
      infoData: [],
      infoDialog: false,
      pageIndex2: 1,
      dataTotal2: 1,
      pageSize2: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
    };
  },
  mounted() {
    // this.getDepartment()
    this.getList();
  },
  methods: {
    getDetail() {
      var _this = this;
      _this.infoData = [];
      _this.OutpatientDomain.MZPersonDetail(
        this.id,
        function (data) {
          _this.infoData = data.data;
          _this.infoDialog = true;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    changePage2(pageIndex) {
      this.pageIndex2 = pageIndex;
      this.getDetail();
    },
    prviewBtn(item) {
      this.id = item.doctorId;
      this.getDetail();
    },
    handleOption(e) {
      this.searchForm.departmentId = e[0];
      this.pageIndex = 1;
      this.getList();
    },
    getDepartment() {
      let orgId = JSON.parse(sessionStorage.getItem("userInfo")).organizationId;
      var _this = this;
      _this.Department.OrgDepartment(
        orgId,
        function (data) {
          _this.departmentData = data.data;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    },
    getList() {
      var _this = this;
      _this.tableData = [];
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.OutpatientDomain.MZOtherChargesInfo(
        item.keyWord,
        item.orderType,
        item.startTime,
        item.endTime,
        this.pageIndex,
        null,
        function (data) {
          _this.tableData = data.data.results;
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    Search(event) {
      this.pageIndex = 1;
      this.getList();
    },
  },
};
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
